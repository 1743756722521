import React from 'react';
import { Seo } from '../components/seo';
import { Layout } from '../layout';
import { graphql, Link } from 'gatsby';
import WorkImg from '../assets/img/bg-work.jpeg';

const WorkPage = ({ data, location }) => {
  // const pageNumber = parseInt(location.search.split("=")[1]) || 1;
  // const edges = data.allMicrocmsWork.edges.slice(
  //   (pageNumber - 1) * 6,
  //   pageNumber * 6
  // );
  return (
    <Layout>
      <Seo
        title="実例紹介"
        description="これまでFINEがこだわり抜いてしてきた仕事の一部を紹介します。"
      />
      <main>
        {/* <!-- ヘッダータイトル --> */}
        <section className="mb-6">
          <div
            style={{ backgroundImage: `url(${WorkImg})` }}
            className=" relative flex items-center justify-center 2xl:-mt-2 w-screen h-40vh bg-cover bg-center overflow-hidden md:h-60vh"
          >
            <h1 className="flex flex-col items-center justify-center tracking-widest">
              <span className="text-white text-2xl md:text-4xl">WORK</span>
              <span className="mt-2 text-white text-xs md:text-base">
                実例紹介
              </span>
            </h1>
            <div>
              <svg
                className=" wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-white fill-current"
                viewBox="0 0 1440 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
              </svg>
            </div>
          </div>
        </section>
        {/* <!-- 現在ページ --> */}
        <section className="mb-6 mx-auto px-6 md:px-10 lg:max-w-5xl lg:max-w-5xl">
          <div className="flex items-center justify-end">
            <div className="flex items-center justify-center">
              <span className="mr-2 text-primary-blue text-xs md:text-sm">
                <Link to="/">TOP</Link>
              </span>
              <span className="mr-2 text-xs md:text-sm">/</span>
              <span className="text-xs md:text-sm">実例紹介</span>
            </div>
          </div>
        </section>
        {/* <!-- 実例紹介 --> */}
        <section className="mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-5xl">
          <div className="flex flex-col items-center justify-center mb-12 lg:mb-20">
            <div className="mb-2 md:mb-4">
              <h2 className="text-primary-blue md:text-xl">実例紹介</h2>
            </div>
            <div className="w-8 h-0.5 bg-primary-blue md:w-12"></div>
          </div>
          <div className=" flex flex-wrap items-center justify-between mb-2 md:mb-8 lg:mb-12">
            {data.allMicrocmsWork.edges.map(
              ({ node: { company, workId, id, images } }, index) => (
                <Link
                  className="mb-6 w-full h-full md:mb-8 md:pr-4 md:w-1/2 lg:mb-16"
                  to={`/work/${workId}`}
                >
                  <div key={index}>
                    <div
                      style={{
                        backgroundImage: `url(${images[0].image.url})`,
                      }}
                      className="mb-2 w-full h-56 rounded md:h-64 lg:h-96 bg-cover"
                    ></div>
                    <div className="flex flex-col">
                      <span className="text-xs font-semibold md:mt-2 md:text-sm lg:text-base">
                        {company}
                      </span>
                    </div>
                  </div>
                </Link>
              )
            )}
          </div>
          {/* <div className="flex items-center justify-evenly mx-auto w-64 md:w-80">
            <Link
              to="/work?page=1"
              className="group flex items-center justify-center  w-8 h-8 hover:bg-primary-blue bg-white border border-primary-blue rounded-full transition duration-300 md:w-10 md:h-10"
            >
              <svg
                className="w-3 h-3 text-primary-blue group-hover:text-white fill-current transition duration-300"
                viewBox="0 0 13 11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.01012 0L7.42512 1.27467L2.82512 5.42434L7.42512 9.574L6.01012 10.8433L0.00012207 5.42163L6.00912 0H6.01012ZM11.4351 0L12.8491 1.27467L8.24912 5.42434L12.8491 9.56769L11.4351 10.8433L5.42412 5.42163L11.4341 0H11.4351Z" />
              </svg>
            </Link>
            {pageNumber - 1 ? (
              <Link
                to={`/work?page=${pageNumber - 1}`}
                className=" group flex items-center justify-center w-8 h-8 hover:bg-primary-blue bg-white border border-primary-blue rounded-full transition duration-300 md:w-10 md:h-10"
              >
                <span className="text-primary-blue group-hover:text-white text-sm font-semibold transition duration-300">
                  {pageNumber - 1}
                </span>
              </Link>
            ) : null}
            <Link
              to={`/work?page=${pageNumber}}`}
              className=" group flex items-center justify-center w-8 h-8 bg-primary-blue hover:bg-primary-blue border border-primary-blue rounded-full transition duration-300 md:w-10 md:h-10"
            >
              <span className=" text-white group-hover:text-white text-sm font-semibold transition duration-300">
                {pageNumber}
              </span>
            </Link>
            {pageNumber <= 1 ? (
              <Link
                to={`/work?page=${pageNumber + 1}`}
                className=" group flex items-center justify-center w-8 h-8 hover:bg-primary-blue bg-white border border-primary-blue rounded-full transition duration-300 md:w-10 md:h-10"
              >
                <span className=" text-primary-blue group-hover:text-white text-sm font-semibold transition duration-300">
                  {pageNumber + 1}
                </span>
              </Link>
            ) : null}
            {pageNumber <= 1 ? (
              <Link
                to={`/work?page=${pageNumber + 2}`}
                className=" group flex items-center justify-center w-8 h-8 hover:bg-primary-blue bg-white border border-primary-blue rounded-full transition duration-300 md:w-10 md:h-10"
              >
                <span className=" text-primary-blue group-hover:text-white text-sm font-semibold transition duration-300">
                  {pageNumber + 2}
                </span>
              </Link>
            ) : null}
            {pageNumber >= 2 ? (
              <Link
                to={`/work?page=${pageNumber + 1}`}
                className=" group flex items-center justify-center w-8 h-8 hover:bg-primary-blue bg-white border border-primary-blue rounded-full transition duration-300 md:w-10 md:h-10"
              >
                <span className=" text-primary-blue group-hover:text-white text-sm font-semibold transition duration-300">
                  {pageNumber + 1}
                </span>
              </Link>
            ) : null}
            <Link
              to="#"
              className=" group flex items-center justify-center w-8 h-8 hover:bg-primary-blue bg-white border border-primary-blue rounded-full transition duration-300 md:w-10 md:h-10"
            >
              <svg
                className=" w-3 h-3 text-primary-blue group-hover:text-white fill-current transform rotate-180 transition duration-300
              "
                viewBox="0 0 13 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.01012 0L7.42512 1.27467L2.82512 5.42434L7.42512 9.574L6.01012 10.8433L0.00012207 5.42163L6.00912 0H6.01012ZM11.4351 0L12.8491 1.27467L8.24912 5.42434L12.8491 9.56769L11.4351 10.8433L5.42412 5.42163L11.4341 0H11.4351Z" />
              </svg>
            </Link>
          </div> */}
        </section>
      </main>
    </Layout>
  );
};

export default WorkPage;

export const query = graphql`
  query {
    allMicrocmsWork {
      edges {
        node {
          company
          workId
          id
          images {
            image {
              height
              url
              width
            }
          }
        }
      }
    }
  }
`;
